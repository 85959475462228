





















































import { reduce } from 'lodash'
import shave from 'shave'
import { Component, Prop } from 'vue-property-decorator'

import Tag from '@/components/_uikit/Tag.vue'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import {
  ExerciseMasterCardResource,
  ExerciseMasterCardTasksResource,
  ExerciseStatus,
  ExerciseType,
} from '@/store/types'
import AuthModule from '@/store/modules/auth'

@Component({
  components: {
    Tag,
  },
})
export default class MasterMyExerciseCard extends MasterGroupMixin {
  @Prop({ required: true })
  private exercise!: ExerciseMasterCardResource

  private get isStatusWait () {
    const { value } = this.exercise.status
    return value === ExerciseStatus.WAIT || value === ExerciseStatus.IN_PROCESS
  }

  private get isStatusChecked () {
    return this.exercise.status.value === ExerciseStatus.CHECKED
  }

  private get isStatusComplete () {
    return this.exercise.status.value === ExerciseStatus.COMPLETE
  }

  private get hasTestTask () {
    return this.exercise.tasks.filter((task: ExerciseMasterCardTasksResource) => task.type && task.type.value === ExerciseType.TEST).length > 0
  }

  private get hasNewMessages () {
    return this.exercise.tasks.filter((task: ExerciseMasterCardTasksResource) => task.newMessagesCount > 0).length > 0
  }

  private get exerciseType () {
    return this.exercise.isProbe ? 'Пробник' : this.exercise.tasks[0] ? this.exercise.tasks[0].type.name : 'Нет задания'
  }

  private get comments () {
    return reduce(this.exercise.tasks, (list: string[], task: ExerciseMasterCardTasksResource) => {
      if (task.comment) {
        list.push(task.comment)
      }
      return list
    }, [])
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private mounted () {
    shave(`#exercise-title-${this.exercise.id}`, 48, { spaces: false })
  }

  /*private routerNavigate() {
    this.$router.push({ name: 'master.exercises.item', params: { exerciseUUID: this.exercise.uuid, groupID: this.currentMasterGroupID.toString() } })
  }

  private handleClick(e: PointerEvent) {
    if (e.pointerType === 'touch') {
      setTimeout(() => {
        this.routerNavigate()
      }, 1500)
      return
    }
    this.routerNavigate()
  }*/
}
